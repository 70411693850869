.doc-list {
  display: flex;
  max-width: 800px;
}


.selected-doc {
  width: 800px;
  box-sizing: border-box;
  position: relative;
}


.selected-item .head {
  width: 250px;
  margin-top: 0;
  padding: 12px 20px;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
}
.selected-item .head.active {
  background-color: var(--block);
  background: linear-gradient(315deg, #1d294c 0%, var(--block3) 100%);
  font-weight: 600;
  border-radius: 10px;
}
@media(max-width: 800px) {
  .selected-doc {
    width: auto;
    position: static;
  }
  .selected-item .head {
    width: auto;
  }
}

.selected-item .head::after {
  content: '';
  display: block;
  border-bottom: 1px solid var(--second);
  width: 0%;
  position: relative;
  top: 12px;
}
.selected-item .head.active::after {
  width: 100%;
  transition: width 8s linear;
}


.selected-item .body {
  position: absolute;
  left: 300px;
  top: 0;
  display: none;
}
.selected-item .body.active {
  display: block;
}
@media(max-width: 800px) {
  .selected-item .body {
    position: static;
  }
  .selected-item .body.active {
    background-color: var(--block);
  }
}
